/*
  This is a placeholder View
*/

// Global npm libraries
import React from 'react'

class Placeholder3 extends React.Component {
  constructor (props) {
    super(props)

    console.log('Placeholder 3 loaded.')
  }

  render () {
    return (
      <>
        <p style={{ padding: '25px' }}>This is placeholder View #3</p>
      </>
    )
  }
}

export default Placeholder3
